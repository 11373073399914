import { useEffect } from 'react';

import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';

import queryDuck from '@/ducks/queryDuck';
import { srpFiltersDuck } from '@/ducks/srp';
import { vdpResultsDuck } from '@/ducks/vdp';

import { PersonalizationEngineModule } from '@/modules';

function PersonalizationEngineContainer({ pageName }) {
    const dispatch = useDispatch();
    const queryData = useSelector(queryDuck.selectors.getDuckState);
    const { fuelType = {} } = useSelector(vdpResultsDuck.selectors.getVdpActiveInventory);
    const { fuelTypeGroup = {} } = useSelector(srpFiltersDuck.selectors.getFiltersOptions);
    const [cookies = {}] = useCookies(['ATC_USER_ZIP', 'auth_consumer_id']);
    useEffect(() => {
        const zipCode = queryData?.zip ?? cookies.ATC_USER_ZIP;
        const consumerId = cookies.auth_consumer_id || '';
        let fuelTypeName;
        switch (pageName) {
            case 'srp': {
                // TODO: need to refactor logic after PEP endpoint to work on Filter change
                const fuelTypes = queryData?.fuelTypeGroup || '';
                const fuelTypeCode = Array.isArray(fuelTypes) ? fuelTypes.pop() : fuelTypes;
                const fuelTypeOption = fuelTypeGroup?.options?.find((item) => item.value === fuelTypeCode);
                fuelTypeName = fuelTypeOption?.label || '';
                break;
            }
            case 'vdp':
                if (fuelType?.group === 'Hybrid: Gas/Electric') {
                    fuelTypeName = 'Hybrid';
                } else if (fuelType?.group === 'Plug-in Hybrid: Gas/Electric') {
                    fuelTypeName = 'Plug-in Hybrid';
                } else {
                    fuelTypeName = fuelType.name || '';
                }
                break;
            default:
                fuelTypeName = '';
        }

        const query = {
            'pc.origin': pageName || '',
            consumerId,
            'pc.fueltype': fuelTypeName,
        };
        dispatch(PersonalizationEngineModule.duck.creators.fetchClientPersonalization(zipCode, query));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
}

export default PersonalizationEngineContainer;
