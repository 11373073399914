import { useDispatch } from 'react-redux';

// Bonnet
import { useRouter } from 'next/router';

import { removeBasePath } from '@bonnet/next/base-path';

import { getPath, pageNames } from '@atc/bonnet-paths';

import vdpResultsDuck from '@/ducks/vdp/vdpResultsDuck';

const { VEHICLE_DETAILS } = pageNames;

export default function useVdpNavigation() {
    const dispatch = useDispatch();
    const router = useRouter();

    /*
     * This function handles the navigation of the srp and also fires a page load in analytics
     *
     * @param listingId
     * @param url: url of the vdp TODO use bonnet paths instead of passing a url, only pass an id
     * @returns {Promise<void>}
     */
    const navigateToVdp = async ({
        listingId,
        url,
        referrer,
        ...rest
    } = {}) => {

        let vdpUrl = url;

        // if no url is sent, build it using bonnet paths and listingId
        if (!url) {
            const query = {
                listingId,
                referrer,
                ...rest,
            };
            vdpUrl = await getPath(VEHICLE_DETAILS, query, {
                referrer: !referrer,
                // nextjs router.push appends basePath
                // as the result of this call is an imperative router push
                // we shall remove the basePath
                basePath: false,
                useUpdatedVdpPath: true,
            });
        }

        // NextJS router.push appends basePath to each url
        // if we are using an vdp url that has not been
        // generated in the above call to bonnet getPath
        // we shall remove the basePath from the href to allow
        // NextJS to do its thing with its Link component
        vdpUrl = removeBasePath(vdpUrl);

        await dispatch(vdpResultsDuck.creators.setActiveResults([listingId]));
        router.push(vdpUrl);
    };

    return navigateToVdp;
}
