import { getWalletConfiguration } from '@atc/bonnet-reference';

import { brands } from 'reaxl-brand';

import paymentsDuck from '@/ducks/paymentsDuck';

export default function withWalletConfigs() {
    return async (ctx) => {
        const [enableMyWallet] = ctx.useFeature('my_wallet');
        const { query, cookies } = ctx;
        const { brand } = ctx.data;

        const lnxMarketingCodes = ['CRMATCWLCM1', 'MYATCALERTSEML', 'BESTDEALS', 'INSIDER'];
        const marketingParam = brand === brands.KBB_BRAND ? 'psid' : 'LNX';

        if (typeof window === 'undefined' && enableMyWallet) {
            try {
                const myWalletConfigs = await getWalletConfiguration();

                const displayValue = query[marketingParam] && lnxMarketingCodes.includes(query[marketingParam]);
                const cookieValue = cookies && cookies.get('WalletCookie');

                const myWalletUpdates = {
                    configs: myWalletConfigs,
                };

                if (displayValue && !cookieValue) {
                    myWalletUpdates.displayModal = true;
                    cookies.set('WalletCookie', 'true', 'Session');
                }

                ctx.store.dispatch(paymentsDuck?.creators?.updateMyWallet(myWalletUpdates));

            } catch (error) {
                // eslint-disable-next-line no-console
                console.error('Error fetching wallet configuration: ', error);
            }
        }
    };
}
